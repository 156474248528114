body {
  overflow: hidden !important; /* Hide scrollbars */
}
.testBox {
  overflow: hidden !important; /* Hide scrollbars */
  /* height: 90% !important; */
}
.react-terminal-wrapper {
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252b32;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
